import utmParamNames from '../constants/utm-params';

const referrerDataService = {
  referrer: '',
  utmParams: '',
  googleCampaign: '',
  saveReferrerIfMissing: saveReferrerIfMissing,
  saveUtmParamsIfMissing: saveUtmParamsIfMissing,
  saveGoogleCampaignIfMissing: saveGoogleCampaignIfMissing,
  getSavedReferrer: getSavedReferrer,
  getSavedUtmData: getSavedUtmData,
  getSavedGoogleCampaign: getSavedGoogleCampaign,
};

function saveUtmParamsIfMissing() {
  if (referrerDataService.utmParams) {
    return;
  }

  const currentUrlString = window.location.href;
  referrerDataService.utmParams = buildUtmInfoString(currentUrlString);
}

function getSavedUtmData() {
  return referrerDataService.utmParams;
}

function saveReferrerIfMissing() {
  if (referrerDataService.referrer) {
    return;
  }

  referrerDataService.referrer = document.referrer;
}

function getSavedReferrer() {
  return referrerDataService.referrer;
}

function saveGoogleCampaignIfMissing() {
  if (referrerDataService.googleCampaign) {
    return;
  }

  const url = new URL(window.location.href);
  referrerDataService.googleCampaign = url.searchParams.get('campid');
}

function getSavedGoogleCampaign() {
  return referrerDataService.googleCampaign;
}

function buildUtmInfoString(currentUrlString) {
  const url = new URL(currentUrlString);

  let referrerString = '';

  utmParamNames.forEach(paramName => {
    const paramValue = url.searchParams.get(paramName.standard);
    if (!paramValue) {
      return;
    }
    const paramString = `${paramName.colloquial}: ${paramValue}, `;
    referrerString += paramString;
  });

  referrerString = referrerString.substring(0, referrerString.length - 2);

  return referrerString;
}

export default referrerDataService;

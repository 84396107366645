const standardUtmParamNames = {
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CAMPAIGN: 'utm_campaign',
  TERM: 'utm_term',
  CONTENT: 'utm_content'
};

const utmParamNames = [
  {
    standard: standardUtmParamNames.SOURCE,
    colloquial: 'Source'
  },
  {
    standard: standardUtmParamNames.MEDIUM,
    colloquial: 'Medium'
  },
  {
    standard: standardUtmParamNames.CAMPAIGN,
    colloquial: 'Campaign'
  },
  {
    standard: standardUtmParamNames.TERM,
    colloquial: 'Term'
  },
  {
    standard: standardUtmParamNames.CONTENT,
    colloquial: 'Content'
  }
];

export default utmParamNames;
